var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","id":"printBill"}},[_c('confirm-dialog',{attrs:{"openDialog":_vm.dialogVoucherDelete,"onClicked":_vm.deleteVoucherConfirm,"onClose":function () {
        _vm.dialogVoucherDelete = false;
      }}}),_c('confirm-dialog',{attrs:{"openDialog":_vm.dialogLock,"onClicked":_vm.changeIsLock,"onClose":function () {
        _vm.dialogLock = false;
      },"dialogTitle":_vm.$t('bills.lockStatus'),"dialogText":!_vm.bill.isLock ? _vm.$t('bills.billLock') : _vm.$t('bills.billunlock')}}),_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary darken-1","dark":""}},[_c('v-card-title',[_c('span',{},[_vm._v(" "+_vm._s(_vm.$t("add") + " " + _vm.$t("vouchers.voucher")))])])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"rules":[
                    function (value) { return value >= 0 || _vm.$t('ThisFieldIsRequired'); } ],"label":_vm.$t('vouchers.voucherAmount'),"max":_vm.remainingAmount,"min":0},model:{value:(_vm.editedItem.voucherAmount),callback:function ($$v) {_vm.$set(_vm.editedItem, "voucherAmount", $$v)},expression:"editedItem.voucherAmount"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('notes')},model:{value:(_vm.editedItem.note),callback:function ($$v) {_vm.$set(_vm.editedItem, "note", $$v)},expression:"editedItem.note"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"white--text",attrs:{"color":"green darken-1","disabled":!_vm.valid,"min-width":100},on:{"click":_vm.save}},[_c('v-icon',[_vm._v("mdi-content-save-outline")]),_vm._v(" "+_vm._s(_vm.$t("save"))+" ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("cancel")))])],1)],1)],1)],1),_c('patient-detail',{attrs:{"id":"patientDetail","patientGuid":_vm.bill.patientGuid}}),_c('v-row',[_c('v-col',{attrs:{"disabled":"","cols":"12","md":"12"}},[_c('v-card',{staticClass:"mt-5"},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_vm._v(" "+_vm._s(_vm.$t("bills.billCode"))+" : "+_vm._s(_vm.bill.billCode)+" ")]),_c('v-col',[_vm._v(" "+_vm._s(_vm.$t("date"))+" : "+_vm._s(_vm._f("moment")(_vm.bill.billDate,"YYYY/MM/DD hh:mm A"))+" ")]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary ","outlined":"","small":""},on:{"click":function($event){return _vm.printItem(_vm.item)}}},'v-btn',attrs,false),on),[_c('v-icon',{},[_vm._v(" mdi-printer ")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("print"))+" ")])])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers1,"items":_vm.table,"hide-default-header":"","hide-default-footer":"","item-class":"cls"},scopedSlots:_vm._u([{key:"item.text",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.text)+" ")]}},{key:"item.value",fn:function(ref){
                  var item = ref.item;
return [(item.isDiscount)?_c('v-row',{staticClass:"title"},[(!_vm.editDiscount)?_c('v-col',{attrs:{"align-self":"center"}},[_vm._v(" "+_vm._s(_vm._f("number")(item.value,"0,0"))+" ")]):_c('v-col',[_c('v-text-field',{staticClass:"title",attrs:{"dense":"","hide-details":"","type":"number","max":_vm.remainingAmount,"min":0,"rules":[
                      function (value) { return value >= 0 || _vm.$t('ThisFieldIsRequired'); } ]},model:{value:(_vm.discountTextField),callback:function ($$v) {_vm.discountTextField=$$v},expression:"discountTextField"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.editDiscount)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.closeDiscount}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red darken-1"}},[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])]):_vm._e(),(!_vm.bill.isLock)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.changeDiscount}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editDiscount ? 'success' : ''}},[_vm._v(" "+_vm._s(_vm.editDiscount ? "mdi-check" : " mdi-pencil")+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(!_vm.editDiscount ? _vm.$t("edit") : _vm.$t("save"))+" ")])]):_vm._e()],1)],1):_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm._f("number")(item.value,"0,0"))+" ")])]}}],null,true)}),_c('v-divider')],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-card',{attrs:{"height":"226"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("bills.groupAmount"))+" ")]),_c('v-card-text',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[(_vm.isInRole('65'))?_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("services.serviceGroupType.general"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("number")(_vm.sumGroup(0),"0,0"))+" ")])]):_vm._e(),(_vm.isInRole('66'))?_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("services.serviceGroupType.dental"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("number")(_vm.sumGroup(1),"0,0"))+" ")])]):_vm._e(),(_vm.isInRole('67'))?_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("services.serviceGroupType.operation"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("number")(_vm.sumGroup(2),"0,0"))+" ")])]):_vm._e()])]},proxy:true}])})],1)],1)],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"id":"payButton"}},[_c('v-col',[(_vm.isInRole('58'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.bill.isPaid,"hide-details":"","color":"success darken-1 "},on:{"click":_vm.dialogOpen}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.bill.isPaid ? _vm.$t("bills.billIsPaid") : _vm.$t("bills.payment"))+" "),_c('v-icon',{staticClass:"mx-2"},[_vm._v(_vm._s(_vm.bill.isPaid ? "mdi-check-outline" : "mdi-checkbook"))])],1)]}}],null,false,2315112840)},[_c('span',[_vm._v(" "+_vm._s(_vm.bill.isPaid ? _vm.$t("bills.billIsPaid") : _vm.$t("bills.payment")))])]):_vm._e()],1),_c('v-spacer'),_c('v-col',{staticStyle:{"text-align":"end"}},[(_vm.isInRole('27'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.remainingAmount > 0,"hide-details":"","color":"primary"},on:{"click":_vm.dialogLockOpen}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("bills.lockStatus"))+" "),_c('v-icon',[_vm._v(_vm._s(!_vm.bill.isLock ? "mdi-lock-open-variant-outline" : "mdi-lock-outline"))])],1)]}}],null,false,2803616622)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.bill.isLock ? "bills.billunlock" : "bills.billIsOpen"))+" ")])]):_vm._e()],1)],1),_c('v-card',{staticClass:"v-card-profile pb-2 mb-4"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("procedures.procedures"))+" ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.bodies,"hide-default-footer":"","width":"100%"},scopedSlots:_vm._u([{key:"item.dateReg",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.dateReg,"YYYY/MM/DD hh:mm A"))+" ")]}},{key:"item.totalAmount",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")((item.amount * item.quantity),"0,0"))+" ")]}},{key:"item.amount",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(item.amount,"0,0"))+" ")]}},{key:"item.serviceGroup",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getServiceGroup(item.serviceGroup))+" ")]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"blue--text text--darken",class:_vm.$vuetify.breakpoint.xsOnly
                ? ' v-data-table__mobile-table-row'
                : ''},[_c('td',{staticClass:"d-none d-sm-flex"},[_vm._v(_vm._s(_vm.$t("total")))]),_c('td',{class:_vm.$vuetify.breakpoint.xsOnly ? 'd-none' : ''}),_c('td',{class:_vm.$vuetify.breakpoint.xsOnly ? 'd-none' : ''}),_c('td',{class:_vm.$vuetify.breakpoint.xsOnly ? 'd-none' : ''}),_c('td',{class:_vm.$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''},[_c('span',{staticClass:"hidden-sm-and-up"},[_vm._v(" "+_vm._s(_vm.$t("procedures.totalAmount"))+" ")]),_vm._v(" "+_vm._s(_vm._f("number")(_vm.sumField("amount"),"0,0"))+" ")]),_c('td',{staticClass:"d-none d-sm-flex"}),_c('td',{class:_vm.$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''},[_c('span',{staticClass:"hidden-sm-and-up"},[_vm._v(" "+_vm._s(_vm.$t("bills.totalAmountFooter"))+" ")]),_vm._v(" "+_vm._s(_vm._f("number")(this.totalAmount,"0,0"))+" ")])])])],2)],1)],1),_c('v-card',{staticClass:"v-card-profile pb-2"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("bills.paidAmounts"))+" ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headersVocher,"items":_vm.vouchers,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","rounded":""},on:{"click":function($event){return _vm.printVoucher(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{},[_vm._v(" mdi-printer ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("print"))+" ")])]),(!item.isLocked && !_vm.bill.isLock && _vm.isInRole('60'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red darken-2","icon":""},on:{"click":function($event){return _vm.deleteVoucher(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("delete")))])]):_vm._e()],1)]}},{key:"item.voucherAmount",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(item.voucherAmount,"0,0"))+" ")]}},{key:"item.dateReg",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.dateReg,"YYYY/MM/DD hh:mm A"))+" ")]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"blue--text text--darken",class:_vm.$vuetify.breakpoint.xsOnly
                ? ' v-data-table__mobile-table-row'
                : ''},[_c('td',{staticClass:"d-none d-sm-flex pa-3"},[_vm._v(_vm._s(_vm.$t("total")))]),_c('td',{class:_vm.$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''},[_c('span',{class:_vm.$vuetify.breakpoint.xsOnly ? '' : 'hidden-sm-and-up'},[_vm._v(" "+_vm._s(_vm.$t("total"))+" ")]),_vm._v(" "+_vm._s(_vm._f("number")(_vm.sumVoucher(),"0,0"))+" ")])])])],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }