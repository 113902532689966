<template>
  <v-expansion-panels v-model="panel">
    <v-expansion-panel v-if="patient">
      <v-expansion-panel-header>
        <v-row no-gutters>
          <v-col cols="auto">
            <v-avatar size="40">
              <v-img
                lazy-src="../assets/user-icon.svg"
                aspect-ratio="1"
                :src="
                  patient.pictureUrl
                    ? patient.pictureUrl
                    : require('@/assets/user-icon.svg')
                "
              >
              </v-img>
            </v-avatar>
          </v-col>
          <!-- src="https://localhost:44364/images/hiclipart.com (1).png" -->
          <v-col class="text-capitalize ma-1 mt-1">
            {{ patient.patientName }}
            <br />
            <span class="grey--text subtitle-2">
              {{ patient.patientCode }}
            </span>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card outlined class="v-card-profile pb-2">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="3" sm="3" style="text-align: center">
                <v-avatar size="150">
                  <v-img
                    lazy-src="../assets/user-icon.svg"
                    aspect-ratio="1"
                    :src="
                      patient.pictureUrl
                        ? patient.pictureUrl
                        : require('@/assets/user-icon.svg')
                    "
                  >
                  </v-img>
                </v-avatar>
              </v-col>

              <v-col
                cols="12"
                md="9"
                sm="9"
                style="margin-top: 10px"
                class="text-capitalize"
              >
                <v-row>
                  <v-col cols="12" md="5" sm="6">
                    <v-icon class="grey--text ma-2">
                      mdi-alert-circle-outline</v-icon
                    >
                    <v-btn
                      min-width="0"
                      :to="'/patientregistration/' + patient.guid"
                      target="_blank"
                      class="pa-0"
                      color="primary"
                      text
                      >{{ patient.patientName }}</v-btn
                    >
                    <br />
                    <span>
                      <v-icon class="grey--text ma-2">
                        mdi-card-account-details-outline</v-icon
                      >
                      {{ patient.patientCode }}
                    </span>
                    <br />
                    <v-icon class="grey--text ma-2">
                      mdi-gender-male-female</v-icon
                    >
                    {{ getGender(patient.patientGender) }}
                    <br />
                    <span>
                      <v-icon class="grey--text ma-2"> mdi-cake-variant</v-icon>
                      {{ (" " + patient.patientDob) | moment("YYYY/MM/DD") }}
                      <br />
                      <v-icon class="grey--text ma-2">
                        mdi-phone-outline</v-icon
                      >
                      {{ patient.patientMobile }}
                    </span>
                    <br />
                  </v-col>
                  <v-col cols="12" md="4" sm="6">
                    <span>
                      <v-icon class="grey--text ma-2">
                        mdi-map-marker-outline</v-icon
                      >
                      <span
                        dir="auto"
                        v-if="patient && patient.city && patient.city.country"
                        >{{ patient.city.country.countryName }},
                        {{ patient.city.cityName }}</span
                      >
                    </span>
                    <br />
                    <v-icon class="grey--text ma-2"> mdi-car-info</v-icon>
                    {{ patient.patientAddress }}
                    <br />
                    <span>
                      <v-icon class="grey--text ma-2">
                        mdi-briefcase-outline</v-icon
                      >
                      {{ patient.patientWork }}
                    </span>
                    <br />
                  </v-col>
                  <v-col cols="12" md="3" v-if="bill && bill.guid">
                    <v-btn
                      :to="'/billDetails/' + bill.guid"
                      target="_blank"
                      color="primary"
                      class="mx-2"
                    >
                      <v-icon> mdi-receipt</v-icon>
                      {{ $t("openBill") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import axios from "axios";

export default {
  props: ["patientGuid"],

  data: () => ({
    panel: [],
    show: false,
    type: "success",
    message: "Completed Successfully",
    appointments: [],
    patient: {},
    bill: {},
    gender: [
      { value: 0, text: "patients.male" },
      { value: 1, text: "patients.female" },
    ],
  }),
  created() {},

  watch: {
    patientGuid(val) {
      axios.get("Patient?guid=" + val).then((response) => {
        this.patient = response.data.data.patient;
        this.bill = response.data.data.bill;
        //console.log(this.bill);
      });
    },
  },
  methods: {
    getGender(i) {
      var x = this.gender.filter((o) => {
        return o.value == i;
      })[0];
      if (x) {
        return this.$t(x.text);
      }
    },
  },
};
</script>

<style></style>
